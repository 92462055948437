import React from 'react'
import { graphql } from 'gatsby'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import FilteredActivities from '../../components/NewTheme/Programmation/FilteredActivities'

const Entrevues = ({data}) => {
  const metaTitle = 'Entrevues | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, entrevues'

  const interviewsEvents = data.allPrismicEvents.edges.filter(({ node }) => {
    return node.data.isentrevue === true
  })

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader title="Entrevues" />
      <div className="container content pb-12">

          {/* TOP TEXT */}
          <div className='text-center'>
              <h4 className='!font-obviously !font-regular !text-xl'>Curieux·ses de savoir ce qui se passe en coulisses de la vie d’artistes? On t’offre un accès direct aux secrets les mieux gardés de la scène! Entre discussions intimes, anecdotes croustillantes, réflexions profondes sur le métier et moments plus légers, nos entrevues sauront combler ta curiosité et satisfaire ta soif de découvertes!                </h4>
          </div>
      
          {/* PROG */}
          <div>
              <FilteredActivities eventList={interviewsEvents} />
          </div>
      </div>
  </Wolflayout>
)
}

export const query = graphql`
  query {
    allPrismicEvents {
      edges {
        node {
          uid
          slug
          data {
            main_image {
              alt
              copyright
              gatsbyImageData
              url
            }
            title {
              text
            }
            start_time
            end_time
            description {
              text
            }
            lpdv_id {
              text
            }
            is_show
            free
            isbienetre
            isentrevue
            isconference
            isplaisirdivers
            isfamille
            hidefromprog
            stage {
              document {
                ... on PrismicStages {
                  uid
                  slug
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Entrevues